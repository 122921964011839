import React from "react"

import "../styles/bootstrap.min.css"
import "./index.css"

import background from '../assets/background-minminminmin.png';
// import burger from '../assets/burger.png';
import plaiz from '../assets/plaiz.svg';
import cloud from '../assets/cloud.svg';
import circle from '../assets/circle.svg';
// import beta from '../assets/beta.svg';
import bouton from '../assets/bouton.svg';
import apple from '../assets/apple.svg';
import android from '../assets/android.svg';
import melty from '../assets/img/melty.png';
import ninki from '../assets/img/ninki.png';
import cocy from '../assets/img/cocy.png';
import essec from '../assets/img/essec.png';
import schoolab from '../assets/img/schoolab.png';
import frenchtech from '../assets/img/frenchtech.png';
import forbes from '../assets/img/forbes.svg';
import vogue from '../assets/img/vogue.svg';
import asos from '../assets/img/asos.svg';

//import communautephotos from '../assets/img/communautephotos.gif';
import communautephotos from '../assets/img/discover2-minminmin.png';
import profilphoto from '../assets/img/profil-min-2.png';
// import photo1 from '../assets/gallerie/photo1-minminmin.png';
// import photo2 from '../assets/gallerie/photo2-minminmin.png';
// import photo3 from '../assets/gallerie/photo3-minminmin.png';
// import photo4 from '../assets/gallerie/photo4-minminmin.png';
// import photo5 from '../assets/gallerie/photo5-minminmin.png';
// import photo6 from '../assets/gallerie/photo6-minminmin.png';
import PAFW from '../assets/PAFW.svg';
// import homme from '../assets/homme.png';
import femmeCercle from '../assets/femmeCercle.svg';
import bouton2 from '../assets/bouton2.svg';
import screenshot from '../assets/screenshot/screenshot-minmin.png';
import facebook from '../assets/reseauxsociaux/facebook.svg';
// import snapchat from '../assets/reseauxsociaux/snapchat.svg';
import instagram from '../assets/reseauxsociaux/instagram.svg';
import linkedin from '../assets/reseauxsociaux/linkedin.svg';
import youtube from '../assets/reseauxsociaux/youtube.svg';
import defile from '../assets/img/defile.gif';

import florian from '../assets/florian.jpg';
import billel from '../assets/billel.jpg';
import bouki from '../assets/bouki.jpg';
import clem from '../assets/clem.png';

import onlink_to_npc4ta_small from '../assets/onlink_to_npc4ta_small.png';
import playstore_en from '../assets/playstore-en.png';
import playstore_fr from '../assets/playstore-fr.png';
import appstore_en from '../assets/appstore-en.png';
import appstore_fr from '../assets/appstore-fr.png';

// import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"
import { Link } from "gatsby"
//import rond_color from "../assets/rond_color.svg"
import testimonialsEnglish from "../assets/testimonialsEnglish-min.png"

const Eng = () => (
  <div>
    <SEO title="Plaiz - The Fashion Social Network"/>
    <div className="QR-div">
        <p style={{color:"black", backgroundColor:"white", fontWeight:"bold", textAlign:"center", boxShadow:"-5px 5px 0px #F14793"}}>Download the app</p>
        <img src={onlink_to_npc4ta_small} className="QR"  alt="QR Code"/> 
      </div>

    <div className="App">

      <header className="App-header">
          <div>
            <img src={background} className="background"  alt="logo Plaiz app"/> 
          </div>
          <div className="logo-and-burger">
            <img src={plaiz} className="logo-plaiz"  alt="logo"/> 
            {/* <Link to='/menu'>
            <img src={burger} className="burger-menu"  alt="logo"/>  
            </Link> */}
            <Link to={"/"} className="english-version">Passer en version française 🇫🇷</Link>
          </div>

          <div class="splash">
            <img src={plaiz} className="splash-plaiz"  alt="logo Plaiz app"/> 
            <p className="splash-titre">PLAIZ</p>
            {/* <p className="splash-soustitre">Le réseau social de la mode</p> */}
          </div>

          <div className="texte-bloc-header">
            <p className="header-titre">PLAIZ</p>
            <p className="header-titre2">Fashion, anti- <br/>social network</p>
            <div>
              <img src={communautephotos} className="heroscreenshot" alt="Plaiz app Discover" />
            </div>
          </div>
          <div className="premier-bloc-download" >
            <p className="telecharge-l-app">Download plaiz ! </p>
            <div className="telecharge-l-app-bouton">
              <a target="_blank" href="https://apps.apple.com/app/plaiz/id1262762421">
                <img src={appstore_en} className="bouton-1 top-button"  alt="logo"/>
              </a>
              <a target="_blank" href="https://play.google.com/store/apps/details?id=com.plaiz.appli"> 
                <img src={playstore_en} className="bouton-2 top-button"  alt="logo"/>
              </a>
            </div>
            <div className="gratuit">
            <p className="gratuit-pour">100% free for the first 100 000 downloads !</p>
            </div>
          </div>
        <div className="milieu-svg">
          <div className="cote-gauche">
            <img src={circle} className="circle"  alt="logo"/> 
          </div>
          <div className="cote-droit">
            <img src={cloud} className="cloud"  alt="logo"/> 
          </div>
        </div>

        <div className="scrolling-wrapper">
          <div className="partenaires">
            <div className="partenaires-scroll-frenchtech">
              <img src={vogue} className="partenaires-scroll" alt="logo" />
            </div>
            <div className="partenaires-scroll-ninki">
              <img src={asos} className="partenaires-scroll" alt="logo" />
            </div>
            <div className="partenaires-scroll-melty">
              <a target="_blank" href="https://www.airofmelty.fr/plaiz-l-anti-reseau-social-qui-entend-conquerir-la-jeune-generation-passionnee-de-mode-a707153.html">
                <img src={melty} className="partenaires-scroll"  alt="logo" />
              </a>
            </div>
            <div className="partenaires-scroll-ninki" >
              <a target="_blank" href="https://ninkimag.fr/plaiz-la-nouvelle-appli-sensationnelle-pour-les-fans-de-mode/">
                <img src={ninki} className="partenaires-scroll-high" alt="logo" />
              </a>
            </div>
            <div className="partenaires-scroll-frenchtech">
              <img src={forbes} className="partenaires-scroll" alt="logo" />
            </div>
            <div className="partenaires-scroll-cocy">
              <a target="_blank" href="https://cocy.fr/2017/10/09/plaiz-lapplication/">
                <img src={cocy} className="partenaires-scroll-high" alt="logo" />
              </a>
            </div>
            <div className="partenaires-scroll-essec">
              <a target="_blank" href="https://www.essec.edu/fr/">
                <img src={essec} className="partenaires-scroll" alt="logo" />
              </a>
            </div>
            <div className="partenaires-scroll-schoolab">
              <a target="_blank" href="https://theschoolab.com/">
                <img src={schoolab} className="partenaires-scroll" alt="logo" />
              </a>
            </div>
            <div className="partenaires-scroll-frenchtech">
              <a target="_blank" href="https://lafrenchtech.com/fr/">
                <img src={frenchtech} className="partenaires-scroll" alt="logo" />
              </a>
            </div>
          </div>
        </div>

      </header>

      <div className="herodiv">
        <img src={communautephotos} className="heroscreenshot2" alt="Plaiz app écran profil" />
      </div>
      
      <body id="eng">
        <div className="bloc-concept">
          <p className="plaiz-titre">Plaiz</p>
          <p className="plaiz-concept-titre">the concept</p>
        </div>
        <div className="bloc-concept-2">
          <p className="bloc-concept-2-texte">Skip the audience-building. Everybody deserves to shine. Everyone is an influencer on Plaiz. Share your outfits to the world. We connect you from the start with like-minded fashion enthusiasts who love the same things you do. And all that without the like-counts, without the followers, without any sort of pressure whatsoever. The safest space on the internet. Period.</p>
          {/* <a className="product-hunt" href="https://www.producthunt.com/posts/plaiz-fashion-anti-social-network?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-plaiz-fashion-anti-social-network" target="_blank">
            <img className="product-hunt" src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=192231&theme=dark" alt="Plaiz - Fashion Anti-Social Network - 100% outfits, no likes, no followers, no headaches | Product Hunt Embed"/>
          </a> */}
        </div>

        <div className="bloc-download" >
          <p className="text-download">Download the app :</p>
          <div className="button-and-icon">
            <a target="_blank" href="https://apps.apple.com/app/plaiz/id1262762421">
              <img src={appstore_en} className="bouton-1" style={{zIndex:150}} alt="logo"/>
            </a>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.plaiz.appli"> 
              <img src={playstore_en} className="bouton-2" style={{zIndex:150}} alt="logo"/>
            </a>
          </div>
          <div style={{paddingTop:20, fontSize:12}}>⭐⭐⭐⭐<span style={{opacity:0.7}}>⭐</span></div>
          <div className="stars-on-store">4.7/5 on the store</div>
        </div>

        {/* <div className="bloc-plaiz-is" >
          <p className="text-plaiz-is-title">Plaiz is the perfect place to:</p>
          <div className="plaiz-is-full">
            <div className="plaiz-is-half">
              <p className="text-plaiz-is">• Post all your outfits, even the ones which aren't perfect enough to be posted on other social networks, those mirror selfies we all have, or the elevator shots.</p>
              <p className="text-plaiz-is">• Express yourself in the most authentic way and without ever being judged.</p>
              <p className="text-plaiz-is">• Talk about fashion, even if you're not an expert.</p>
              <p className="text-plaiz-is">• Try new styles, and be the boldest and most creative version of yourself.</p>
            </div>
            <div className="plaiz-is-half-2">
              <p className="text-plaiz-is">• Review your latest purchase, or a shop you visited.</p>
              <p className="text-plaiz-is">• Save the outfits that inspire you, posted by real people, not only superstars (yes, Beyoncé isn't on Plaiz yet).</p>
              <p className="text-plaiz-is">• Gather your inspirations that you took anywhere else, and show the world what you love.</p>
              <p className="text-plaiz-is">• Discover some emerging brands' universe, the ones of some other creative minds, and show them yours.</p>
            </div>
          </div>
        </div> */}

        <div className="communaute">
          <div className="communaute-gauche">
            <p className="communaute-gauche-titre">Join your</p> 
            <p className="communaute-gauche-titre-2"> style gang </p> 
            <p className="communaute-gauche-texte">
              For the bold ones, the misfits, who are careless but detail-oriented, passionate, creative, innovate, join Plaiz now. 
              <br/><br/>
              We believe in a place where everyone should feels comfortable sharing their style. We want and need self-expression, diversity, variety, openess, because that's would would make fashion greater, that's what makes creativity.
              <br/><br/>
              So don't wait until everyone gets there to get there.
              </p>
          </div>
          <div className="communaute-droit">
            <img src={profilphoto} className="communautephotos" alt="Plaiz app profile" />
          </div>
        </div>


        <div className="bloc-concept">
          <p className="plaiz-titre">Honestly,</p>
          <p className="plaiz-concept-titre">everybody loves us !</p>
          <div className="bloc-testimonials">
            <img src={testimonialsEnglish} className="testimonials" alt="plaiz app testimonials"/> 
          </div>
        </div>

        <div className="bloc-download" >
          <p className="text-download">Join the gang :</p>
          <div className="button-and-icon">
            <a target="_blank" href="https://apps.apple.com/app/plaiz/id1262762421">
              <img src={appstore_en} className="bouton-1" style={{zIndex:150}} alt="logo"/>
            </a>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.plaiz.appli"> 
              <img src={playstore_en} className="bouton-2" style={{zIndex:150}} alt="logo"/>
            </a>
          </div>
        </div>

        <img src={PAFW} className="PAFW" alt="img" />
        <div className="plaiz-week">
          <div className="plaiz-week-cote-gauche">
            <img src={defile} className="defile" id="defile" alt="plaiz fashion show" style={{borderRadius:20}}/>
            <img src={femmeCercle} className="femmeCercle" alt="img" />
          </div>
          <div className="plaiz-week-cote-droit">
            <div className="plaiz-week-cote-droit-titre-responsive"> 
              <p className="plaiz-week-cote-droit-titre">Beyond the app: <span className="plaiz-week-cote-droit-titre-2">The Plaiz Week</span></p>
            </div>
            <p className="plaiz-week-cote-droit-texte">Fashion shows, live performances (concerts, dansers, graffiti...), parties... but without the fake good manners and the glitters. The Plaiz Anti Fashion Week <span className="span">(#PAFW)</span> takes you to a week-long underground showcasing of the creativity of <span className="span"> Plaiz Gang's</span> favorite emerging brands. And secret, subculture fashion week, hidden in a<span className="span"> 2.000 sqm warehouse</span>. For the fearless, willing to make fashion fun again, while remaining a noble art. A fashion week during which we All are the artists.</p>
          </div>
        </div>
        
        {/* <div className="gallerie-plaiz">
          <div className="gallerie-plaiz-premiere-partie">
            <img src={photo1} className="photo1" alt="img"/>
            <img src={photo2} className="photo2" alt="img"/>
            <img src={photo3} className="photo3" alt="img"/>
          </div>
          <div className="gallerie-plaiz-deuxieme-partie">
            <img src={photo4} className="photo4" alt="img"/>
            <img src={photo5} className="photo5" alt="img"/>
            <img src={photo6} className="photo6" alt="img"/>
          </div>
        </div> */}

        <div className="inscription">
        <img src={circle} className="circle-inscription" alt="img" />
          <div className="inscription-gauche" >
          <div className="inscription-gauche-download">
            <div className="inscription-gauche-titre-1">
              <p className="inscription-gauche-titre">Download</p>
              <p className="inscription-gauche-titre-2">the app</p>
            </div>
            <div className="inscription-gauche-t">
              <p className="inscription-gauche-texte">Right now, we're in VIP mode 🎫 :</p>
              <p className="inscription-gauche-texte">By signing up now, you'll be invited to the exclusive events. <span className="inscription-gauche-texte-2"> (But be quick, the VIP mode is limited).</span> </p>
            </div>
            </div>
    
            <div className="download-middle">
              <a target="_blank" href="https://apps.apple.com/app/plaiz/id1262762421">
                <img src={appstore_en} className="bouton-1" style={{zIndex:150}} alt="logo"/>
              </a>
              <a target="_blank" href="https://play.google.com/store/apps/details?id=com.plaiz.appli"> 
                <img src={playstore_en} className="bouton-2" style={{zIndex:150}} alt="logo"/>
              </a>
            </div>
          </div>
          <div className="inscription-droite">
            <img src={screenshot} className="screenshot" alt="img" />
          </div>
        </div>

        <div className="bloc-download" >
          <p className="text-not-convinced">Still not ready to join ?</p>
          <p className="text-follow-us">Follow us on your <a className="classic-networks" href="https://www.instagram.com/plaiz_gang/">regular social networks</a> and we'll let you know when everybody gets here ! 🤙</p>
        </div>


        <div className="bloc-download" >
          <p className="text-not-convinced">Made with ❤️ and ☕️ by our team:</p>
          <div className="our-pictures-block">
            <div style={{marginLeft:10, marginRight:10}}>
              <img src={bouki} className="our-pictures" alt="img" />
              <p>Bouki</p>
            </div>
            <div style={{marginLeft:10, marginRight:10}}>
              <img src={florian} className="our-pictures" alt="img" />
              <p>Flo</p>
            </div>
            <div style={{marginLeft:10, marginRight:10}}>
              <img src={billel} className="our-pictures" alt="img" />
              <p>Bill</p>
            </div>
            <div style={{marginLeft:10, marginRight:10}}>
              <img src={clem} className="our-pictures" alt="img" />
              <p>Clem</p>
            </div>
          </div>
          <p className="text-not-convinced">And many other that we love</p>
        </div>

        <div className="bloc-download" style={{marginTop:100}}>
          <p className="text-not-convinced">Features coming soon 😱</p>
          <div style={{width:"80%", alignSelf:"center"}}>
          <p className="text-features" style={{textAlign:"left"}}>
            💰 Make money when you post : we analyze your outfits, suggest similar articles. If someone buys, you make money.
            <br/><br/><br/>
            🛍 Buy and sell your clothes, whether it's your own, or your creations
            <br/><br/><br/>
            👘 Build relationships with brands : we offer you at least 6k views, that makes you an influencer. Get in touch with brands through our platform.
            <br/><br/><br/>
            🎁 Get free clothes that members of the community give away
          </p>
          </div>
        </div>

        <div className="bloc-download" >
          <p className="text-download">Team iOS or Android ?</p>
          <div className="button-and-icon" style={{marginBottom:100}}>
            <a target="_blank" href="https://apps.apple.com/app/plaiz/id1262762421">
              <img src={appstore_en} className="bouton-1" style={{zIndex:150}} alt="logo"/>
            </a>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.plaiz.appli"> 
              <img src={playstore_en} className="bouton-2" style={{zIndex:150}} alt="logo"/>
            </a>
          </div>
        </div>

        <div className="bloc-download" >
          <p className="text-not-convinced">You are a good person 🥰</p>
          <p className="text-follow-us">Tap <Link className="classic-networks" to={"/good-person"}>here</Link> to discover why ✨</p>
        </div>
      </body>

      <div className="app-footer">
        <div className="footer">

          <img src={circle} className="circle-footer" alt="img" />
          
          <div className="aPropos" >
            <p className="aPropos-titre">About<span className="aPropos-titre-2">Plaiz</span></p>
            <p className="aPropos-texte">We are a space of creation and conversation, but like-minded fashion enthusiasts, whether they just started loving it, whether they are knowledgeable, brands or professionals. Be bold, you are gold.</p>
            
            <div className="reseauxSociaux">
              <a target="_blank" href="https://fr-fr.facebook.com/superplaiz/"> 
                <img src={facebook} className="facebook" alt="img"/>
              </a>
              {/* <a target="_blank"> 
                <img src={snapchat} className="snapchat" alt="img"/>
              </a> */}
              <a target="_blank" href="https://www.instagram.com/plaiz_gang/?hl=fr">
                <img src={instagram} className="instagram" alt="img"/>
              </a>
              <a target="_blank" href="https://www.linkedin.com/company/plaiz">
                <img src={linkedin} className="linkedin" alt="img"/>
              </a>
              <a target="_blank" href="https://www.youtube.com/channel/UCWexBI920O8mbza0u_k0S5g">
                <img src={youtube} className="youtube" alt="img"/>
              </a>
            </div>

            <p className="reseauxSociaux-y">Show your love !</p>
          </div>

          <div className="contacteNous">
            <p className="contact">Get<span className="contact-titre">in touch</span></p>
            <p className="contact-texte"> 5 parvis Alan Turing</p>
            <p className="contact-texte" > 75013, Paris. </p>
            <p className="contact-texte">+33 669 773 161 - hello@plaiz.io</p>
          </div>

          {/* <div className="contacteNous2">
            <p className="contact">Additional<span className="contact-titre">support</span></p>
            <p className="contact-texte" style={{lineHeight: 1.5, paddingTop: 10}}>💪 We stand for diversity, inclusion, equality, climate action, body positivism and lgbtqia+ rights.</p>
            <p className="contact-texte" style={{paddingTop: 10}}>💝 Do you need <span style={{textDecorationLine:"underline", color:"#F14793"}}>mental health support ?</span></p>
            <p className="contact-texte">🌍 We're in this together, let's get the vaccine </p>
          </div> */}

        </div>

        <div className="cloud-div">
          <img src={cloud} className="cloud-footer-eng"  alt="logo"/>
        </div>

        <div className="bloc-mis">
          <p className="mis">Made in the streets 🌹</p>
        </div> 

      </div>

      <Link className="classic-networks" to={"/plaiz-x-watiz-en"}>
      <div className="Watiz-div">
        <p style={{color:"white", fontWeight:"bold", textAlign:"center"}}> 🔥 PLAIZ joins the WATIZ familly, click here to know more about it. And download WATIZ ! 🔥</p>
      </div>
      </Link>

    </div>
  </div>

)

export default Eng